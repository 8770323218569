<template>
  <div>
    <v-app-bar :dark="false" :elevation="0" app class="backofficeAppBar hiddePrintElement">
      <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
          :lazy-src="logo"
          :src="logo"
          alt="BACKOFFICE"
          class="cursor-pointer-action mx-0 mx-md-16"
          max-width="90"
          @click="$router.push('/dashboard')"
      />
      <v-spacer/>
      <div class="d-none d-md-flex display-align-center mr-2">
        <div>
        <span class="login-user-text"
        >{{ getDayTime }}, {{ getUser.name || "" }}</span
        >
        </div>
        <div class="ml-4">
          <v-avatar size="40">
            <img
                :alt="getUser.name"
                src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
            />
          </v-avatar>
        </div>
      </div>
      <v-btn disabled class="hiddePrintElement" dark icon @click="toggleTheme">
        <v-icon>mdi-brightness-4</v-icon>
      </v-btn>
      <v-menu :min-width="140" transition="scroll-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="hiddePrintElement" dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense class="d-none d-md-block">
          <v-list-item
              v-for="item in menu"
              :key="item.title"
              link
              @click="handleClickMenu(item)"
          >
            <v-list-item-content>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-content>

            <v-list-item-content>
              <v-list-item-title class="menu-title"
              >{{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense class="d-block d-md-none">
          <v-list-item
              v-for="item in menuResponsive"
              :key="item.title"
              link
              @click="handleClickMenu(item)"
          >
            <div class="d-flex flex-column my-2">
              <v-icon>{{ item.icon }}</v-icon>
              <v-spacer/>
              <v-list-item-title class="menu-title ml-2">
                {{ item.title }}
              </v-list-item-title>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        temporary
        sticky
        fixed
    >
      <v-list
          nav
          dense
      >
        <v-list-item class="px-2">
          <v-list-item-icon>
            <v-icon size="30">mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ getUser.name || 'N/A' }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ getUser.email || 'N/A' }}</v-list-item-subtitle>

            <v-list-item-subtitle v-if="getUser && getUser.roles.length">
              <div v-for="(role, index) in getUser.roles" :key="index" >
                <span style="font-size: 10px; color: var(--v-textCalendar-base); font-weight: bold">{{role}}</span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>


        <v-list-item-group>
          <div v-for="(itemMenu, index) in items" :key="index">
            <v-list-item v-if="showByRol(itemMenu)" @click="handleClickMenu(itemMenu)">
              <v-list-item-icon>
                <v-icon>{{ itemMenu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ itemMenu.title }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Logo from "../assets/images/icon-white.png";
import router from "../router";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "AppBar",
  data() {
    return {
      logo: Logo,
      drawer: false,
      items: [
        {title: "Pacientes", icon: "mdi-clipboard-account", path: "/patients"},
        {title: "Permisos", icon: "mdi-badge-account", path: "/employees"},
        {title: "Productos", icon: "mdi-package-variant", path: "/products"},
        {title: "Inventario por tienda", icon: "mdi-store-cog-outline", path: "/inventory-stores"},
        {title: "Inventario por paciente", icon: "mdi-store-cog-outline", path: "/inventory-patient"},
        {title: "Órdenes de inventario", icon: "mdi-order-bool-descending-variant", path: "/store-stocks"},
        {title: "Roles", icon: "mdi-shield-key", path: "/role"},
      ],
      menu: [
        {title: "Perfil", icon: "mdi-account-cog", path: "/profile"},
        {title: "Cerrar Sesión", icon: "mdi-logout", path: "/"}],
      menuResponsive: [
        {title: "Perfil", icon: "mdi-account-cog", path: "/profile"},
        {title: "Cerrar Sesión", icon: "mdi-logout", path: "/"}
      ],

    };
  },
  created() {
    this.$vuetify.theme.dark = false
   /* const darkTheme = localStorage.getItem('darkTheme')
    if (darkTheme !== null) {
      darkTheme === 'false' ? this.$vuetify.theme.dark = false : this.$vuetify.theme.dark = true
    }*/
  },
  computed: {
    getDayTime() {
      const hour = moment().hour();
      if (hour >= 6 && hour < 12) {
        return "Buenos días";
      } else if (hour >= 12 && hour < 20) {
        return "Buenas tardes";
      } else {
        return "Buenas noches";
      }
    },
    ...mapGetters("auth", ["getResources", "getUser"])
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem('darkTheme', String(this.$vuetify.theme.dark))
    },
    logout() {
      this.$store.commit("auth/CLEAR_TOKEN");
      localStorage.clear()
      router.replace({path: "/"});
    },
    handleClickMenu(item) {
      switch (item.path) {
        case "/":
          this.logout();
          break;
        default:
          router.push(item.path);
          break;
      }
    },
    showByRol(item) {
      return this.getResources.find((itemResource) => itemResource.resourceName === item.path)
    }
  },
};
</script>

<style lang="scss" scoped>
.backofficeAppBar {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
          var(--v-appBarInitial-base) 19%,
          var(--v-appBarInitial-base) 88%
  );
}

.btn-link {
  font-family: "Montserrat-Light";
  text-transform: none;
}

.menu-title {
  font-family: "Montserrat-Medium";
}

.login-user-text {
  font-size: 16px;
  font-family: "Montserrat-Regular";
  color: var(--v-whiteText-base);
}

.circle > div {
  border: 2px solid var(--v-avatarBorder-base);
}


@media print {
  .hiddePrintElement {
    display: none;
  }
}
</style>
