<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        :showRightItem="false"
        subtitle="Crea, busca, filtra y visualiza inventario por paciente"
        title="Inventario por paciente"
        principalBtnTitle="Descargar inventario"
    />
  <inventory-patient-resume></inventory-patient-resume>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "../components/TopInfoAction.vue";
import InventoryPatientResume from "../components/inventoryPatient/InventoryPatientResume.vue";

export default {
  name: "inventoryPatient",
  components: {InventoryPatientResume, TopInfoAction, Breadcrumbs},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Inventario por paciente",
        disabled: true,
        path: "/inventory-patient",
      },
    ],
  }),
  methods: {

  },
};
</script>

<style lang="scss" scoped></style>
