<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction
        :show-secondary-btn="isWizard ? false : true"
        :title="showTitle"
        secondary-btn-title="Regresar"
        subtitle="Llena el formulario y presiona el boton guardar"
        @setDefaultSecondaryAction="cancel"
    />

    <inventory-patient-product-resume :patient-id="$route.params.id"></inventory-patient-product-resume>
    <inventory-patient-cardex-resume></inventory-patient-cardex-resume>
    <inventory-patient-diagnosis-resume :patient-id="$route.params.id"></inventory-patient-diagnosis-resume>
    <inventory-patient-alergic-resume :patient-id="$route.params.id"></inventory-patient-alergic-resume>

    <div v-if="loaded">
      <BottomActions
          :show-secondary-btn="isWizard ? false : true"
          secondary-btn-title="Regresar"
          @setDefaultSecondaryAction="cancel"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Patient'"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import TopInfoAction from "../components/TopInfoAction.vue";
import BottomActions from "../components/BottomActions.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import moduleMixin from "../mixins/moduleMixin";
import InventoryPatientProductResume from "../components/inventoryPatient/InventoryPatientProductResume.vue";
import InventoryPatientCardexResume from "../components/inventoryPatient/InventoryPatientCardexResume.vue";
import InventoryPatientDiagnosisResume from "../components/inventoryPatient/InventoryPatientDiagnosisResume.vue";
import InventoryPatientAlergicResume from "../components/inventoryPatient/InventoryPatientAlergicResume.vue";

export default {
  name: "CRUDInventoryStore",
  mixins: [CRUDMixin, moduleMixin],
  props: {
    pathBack: {type: String, default: "inventory-patient"},
  },
  components: {
    InventoryPatientAlergicResume,
    InventoryPatientDiagnosisResume,
    InventoryPatientCardexResume,
    InventoryPatientProductResume,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
    BottomActions,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Inventario por paciente",
          disabled: false,
          path: "/inventory-patient",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nuevo"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } Inventario por paciente`,
          disabled: true,
          path: "/inventory-patient",
        },
      ],
      currentElement: {
        storeId: this.$route?.params?.id,
        inventoryStore: [],
        products: [],
        storeStock: []
      },
      storeName: '',
      module: "",
      titleCreate: "Crear inventario por paciente",
      titleEdit: `Editar inventario por paciente`,
      data: [
        {
          storeId: "88305a80-1534-447f-999e-3b885b76d69e",
          storeName: "Stock Grande",
          totalStock: "Juan jose",
          status: 'GOOD'
        },
        {
          storeId: "4ae9bc64-2b83-438e-a257-138ebbd31280",
          storeName: "Centro Morelia",
          totalStock: "Pedro",
          status: 'GOOD'
        },
        {
          storeId: "0fac16c0-0f8f-4db1-9596-dc9e07259eaa",
          storeName: "Bosque Morelia",
          totalStock: "Josefina",
          status: 'CRITICAL'
        }
      ],
    };
  },
  computed: {
    getPatientName() {
      return this.data.find((item) => item.storeId === (this.$route.params?.id))?.totalStock
    },
    showTitle() {
      let title = "";
      if (this.$route.params?.crudAction) {
        switch (this.$route.params?.crudAction) {
          case "create":
            title = this.titleCreate;
            break;
          case "edit":
            title = `${this.titleEdit} ${this.getPatientName || ''}`;
            break;
          default:
            title = "";
            break;
        }
      }
      return title;
    },
  },

  methods: {
    validFormInput(e) {
      this.validForm = e;
    },
  },
};
</script>

<style scoped></style>
