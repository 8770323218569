<template>
  <div class="mt-5">
    <div>
      <v-data-table
          :headers="headers"
          :items="dataSource"
          :mobile-breakpoint="200"
          :loading="loaded"
          :options.sync="options"
          :search="search"
          :server-items-length="totalItems"
          class="mt-5"
          item-key="storeId"
          hide-default-header
          :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
      >
        <template v-slot:top>
          <div>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Alergia</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="dialogAllergie"
                  max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      rounded
                      class="btn-active ma-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Agregar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                                  <span class="text-h5">{{
                                      editedItem?.uuid ? 'Editar Alergia ' : 'Crear Alergia'
                                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                          <div>
                            <label class="input-label" for="street">Descripción<span
                                class="required-field-form">*</span></label>
                            <v-textarea
                                v-model.number="editedItem.description"
                                id="street"
                                :rules="required"
                                hide-details
                                outlined
                                color="backofficeInput"
                                placeholder="Escribe una descripción"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeForm"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                        @click="saveUnit"
                        :disabled="!disabledDialogForm"
                        color="blue darken-1"
                        text
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </div>
        </template>

        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.description }}</span>
            </td>
            <td>
              <v-icon
                  small
                  @click="editAllergie(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  class="ml-2"
                  small
                  @click="deleteAllergie(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Alergia'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
    <ActionPopUp
        :dialog="deleteAllergieDialog"
        :iteName="'Alergia'"
        :type="typeDelete"
        @cancelAction="deleteAllergieDialog = false"
        @closeAction="closeActionEdit"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import tableMixin from "../../mixins/tableMixin.js";
import ActionPopUp from "../ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import customStoreService from "../../services/customStore";
import customStore from "../../services/customStore";
import {required} from "../../utils/validationRule";


export default {
  name: "InventoryPatientAlergicResume",
  components: {ActionPopUp},
  props: {
    patientId: {type: String, default: ''}
  },
  mixins: [moduleMixin, tableMixin],
  data() {
    return {
      required,
      dialogAllergie: false,
      deleteAllergieDialog: false,
      typeDelete: 'delete',
      statusInventory: {
        'CRITICAL': '#bb1a2f',
        'GOOD': '#4b8333',
        'MINIMAL': '#e69138'
      },
      headers: [],
      module: "allergies",
      label: "Busca por Alergia",
      selectedRow: {},
      editedItem: {
        description: '',
      },
    };
  },
  computed: {
    disabledDialogForm() {
      return this.editedItem.description
    }
  },
  created() {
    this.additionalParams = {
      patientId: this.patientId
    }
  },
  methods: {
    afterProcess() {
      this.headers = [
        {
          text: "Alergia",
          sortable: true,
          value: "allergie",
        },
        {
          text: "",
          sortable: true,
          value: "actions",
        },
      ];
    },
    closeForm() {
      this.editedItem = {}
      this.dialogAllergie = false
      this.editedItem = {
        description: null,
      }
    },
    async saveUnit() {
      const data = {
        patientId: this.patientId,
        ...this.editedItem
      }
      try {
        await customStore.postDataByModule('allergies', data)
        await this.loadData()
        this.closeForm()
      } catch (errorMessage) {
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialog = true
      }
    },
    editAllergie(item) {
      this.editedItem.description = item.description
      this.editedItem.uuid = item.uuid
      this.dialogAllergie = true
    },
    deleteAllergie(item) {
      this.selectedRow = item
      this.deleteAllergieDialog = true
    },
    closeActionEdit() {
      this.selectedRow = {}
      this.deleteAllergieDialog = false
    },
    async deleteAction() {
      try {
        await customStoreService.deleteByModule('allergies', this.selectedRow);
        await this.loadData()
        this.closeActionEdit()
      } catch (errorMessage) {
        this.closeActionEdit()
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialog = true
      }
    },
  },
};
</script>

<style scoped></style>
