<template>
  <div class="mt-5">
    <div>
      <v-data-table
        :headers="headers"
        :items="data"
        :mobile-breakpoint="200"
        :search="search"
        class="mt-5"
        item-key="storeId"
        hide-default-header
      >

        <template v-slot:top>
          <div>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Cardex</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="dialog"
                  max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      disabled
                      rounded
                      class="btn-active ma-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Agregar
                  </v-btn>
                </template>
              </v-dialog>
            </v-toolbar>
            <div class="mx-4 mb-4">
              <v-text-field
                  v-model="search"
                  :label="label"
                  hide-details
                  outlined
                  prepend-icon="mdi-magnify"
                  @click:append="cleanText"
                  :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
                  single-line
              ></v-text-field>
            </div>
          </div>
        </template>

        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.product  }}</span>
            </td>
            <td>
              <span>{{ item.productKey  }}</span>
            </td>
            <td>
              <span>{{ item.dosis  }}</span>
            </td>
            <td>
              <span>{{ item.unidad  }}</span>
            </td>
            <td>
              <span>{{ item.frecuencia  }}</span>
            </td>
            <td>
              <span>{{ item.start  }}</span>
            </td>
            <td>
              <span>{{ item.end  }}</span>
            </td>
            <td>
              <span>{{ item.predictionWeek  }}</span>
            </td>
            <td>
              <span>{{ item.predictionMonth  }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
<!--    <ActionPopUp
      :dialog="dialog"
      :iteName="'Store'"
      :type="type"
      :message="message"
      @cancelAction="dialog = false"
      @closeAction="closeAction"
      @deleteAction="deleteAction"
    ></ActionPopUp>-->
  </div>
</template>

<script>
/*import tableMixin from "../../mixins/tableMixin.js";*/
/*import ActionPopUp from "../../components/ActionPopUp.vue";*/
import moduleMixin from "@/mixins/moduleMixin";
/*import DownloadBtn from "../downloadBtn.vue";*/

export default {
  name: "InventoryPatientCardexResume",
  components: {  },
  mixins: [moduleMixin],
  data() {
    return {
      dialog:false,
      statusInventory: {
        'CRITICAL': '#bb1a2f',
        'GOOD': '#4b8333',
        'MINIMAL': '#e69138'
      },
      headers:[],
      search:'',
      module: "store-stocks/inventory",
      label: "Busca por producto",
      statusFilter: [],
      statusList: [{name:'Crítico', id:'CRITICAL'}, {name:'Bueno', id:'GOOD'}, {name :'Mínimo', id:'MINIMAL'}],
      data: [
          {
            storeId: "88305a80-1534-447f-999e-3b885b76d69e",
            product: "Medica",
            productKey: 'MA058',
            dosis: "1/4",
            unidad: 'Pastilla',
            frecuencia: 'Cada 6 horas por la mañana',
            start:'10/06/2024',
            end:'15/06/2024',
            predictionWeek:'10',
            predictionMonth:'50'
          },
          {
            storeId: "4ae9bc64-2b83-438e-a257-138ebbd31280",
            product:'Paracetamol',
            productKey: 'ME88',
            dosis: "1",
            unidad: 'Pastilla',
            frecuencia: '2 veces al día',
            start:'Sin suspender',
            end:'Sin suspender',
            predictionWeek:'1',
            predictionMonth:'5'
          },
        ],
    };
  },
  created() {
    this.headers = [
      {
        text: "Producto",
        sortable: true,
        value: "product",
      },
      {
        text: "Clave producto",
        sortable: true,
        value: "productKey",
      },
      {
        text: "Dosis",
        sortable: true,
        value: "dosis",
      },
      {
        text: "Unidad",
        sortable: true,
      },
      {
        text: "Frecuencia",
        sortable: true,
      },
      {
        text: "Inicio",
        sortable: true,
      },
      {
        text: "Termino",
        sortable: true,
      },
      {
        text: "Predicción Semanal",
        sortable: true,
      },
      {
        text: "Predicción mensual",
        sortable: true,
      },
    ];
  },
  methods: {
    goTo(row, pathName, crudAction, origin = null, optionalParam = null) {
      this.$router.push({
        name: pathName,
        params: {crudAction: crudAction, id: row.uuid ?? optionalParam, origin: origin},
      });
    },
    async cleanText() {
      this.search = ""
    },
  },
};
</script>

<style scoped></style>
