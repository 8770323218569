<template>
  <div class="mt-5">
    <div>
      <v-row>
        <v-col cols="12" lg="8" md="8" sm="8" xl="8">
          <v-text-field
              v-model="search"
              :label="label"
              hide-details
              outlined
              prepend-icon="mdi-magnify"
              @click:append="cleanText"
              :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
              single-line
              @input="typingSearch"
          />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-select
              v-model="statusFilter"
              @change="changeFilterStatus"
              :items="statusList"
              item-text="name"
              item-value="id"
              label="Status"
              multiple
              outlined
          />
        </v-col>
        <!--        <v-col  cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                  <download-btn module="/inventory/download" titleBtn="Inventario" fileDownloadName="Inventario"></download-btn>
                </v-col>-->
      </v-row>
      <v-data-table
          :headers="headers"
          :items="dataSource"
          :mobile-breakpoint="200"
          :loading="loaded"
          :options.sync="options"
          :search="search"
          :server-items-length="totalItems"
          class="mt-5"
          item-key="storeId"
          hide-default-header
          :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
      >

        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item?.product?.name }}</span>
            </td>
            <td>
              <span>{{ item?.product?.productCategory?.name }}</span>
            </td>
            <td>
              <span>{{ item.stock }}</span>
            </td>
            <td>
              <div class="border-circle" :style="{'background-color': statusInventory[item.status]}"></div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Store'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import ActionPopUp from "../ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import tableMixin from "../../mixins/tableMixin";

/*import DownloadBtn from "../downloadBtn.vue";*/

export default {
  name: "InventoryPatientProductResume",
  props: {
    patientId: {type: String, default: ''}
  },
  components: {ActionPopUp},
  mixins: [moduleMixin, tableMixin],
  data() {
    return {
      statusInventory: {
        'CRITICAL': '#bb1a2f',
        'GOOD': '#4b8333',
        'MINIMAL': '#e69138'
      },
      module: `patient-stocks`,
      label: "Busca por producto o tipo",
      statusFilter: [],
      statusList: [{name: 'Crítico', id: 'CRITICAL'}, {name: 'Bueno', id: 'GOOD'}, {name: 'Mínimo', id: 'MINIMAL'}],
    };
  },
  created() {
    this.additionalParams = {
      patientId: this.patientId
    }
    this.headers = [
      {
        text: "Producto",
        sortable: true,
        value: "product",
      },
      {
        text: "Tipo",
        sortable: true,
        value: "type",
      },
      {
        text: "Status",
        sortable: true,
      },
      {
        text: "Cantidad",
        sortable: true,
      },
    ];
  },
  methods: {
    changeFilterStatus() {
      let statusFormatted = "";
      this.statusFilter.forEach((status) => {
        statusFormatted = `${statusFormatted}&status=${status}`
      });
      this.status = statusFormatted;
      this.loadData()
    },
  },
};
</script>

<style scoped></style>
