<template>
  <v-container max-width="1200">
    <Breadcrumbs v-if="!isWizard" :items="breadcrumbsItems"/>
    <top-info-action
        :valid-btn="hasAccess('/patients', 'POST')"
        :show-right-item="true"
        :show-secondary-btn="isWizard ? false : true"
        :title="showTitle"
        :show-icon-action="$route.params.crudAction === 'edit' ? true : false"
        iconAction="mdi-printer"
        principal-btn-title="Guardar"
        secondary-btn-title="Cancelar"
        subtitle="Completa el formulario y da click en guardar"
        @setDefaultPrimaryAction="save"
        @actionIcon="actionIcon"
        @setDefaultSecondaryAction="cancel"
    ></top-info-action>
    <div v-if="loaded">
      <form-register
          id="printable-section"
          :hide-print="false"
          v-if="loaded"
          :current-element="currentElement"
          :hide-field="$route.params.crudAction === 'edit' ? true : false"
          @addNewAnalysis="addNewAnalysis"
          @deleteAnalysis="deleteAnalysis"
          @addNewDiagnosis="addNewDiagnosis"
          @deleteDiagnosis="deleteDiagnosis"
          @addNewMedication="addNewMedication"
          @deleteMedication="deleteMedication"
      ></form-register>
      <BottomActions
          :show-secondary-btn="isWizard ? false : true"
          principal-btn-title="Guardar"
          secondary-btn-title="Cancelar"
          :valid-btn="hasAccess('/patients', 'POST')"
          @setDefaultPrimaryAction="save"
          @setDefaultSecondaryAction="cancel"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Registro'"
        :print="print"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TopInfoAction from "@/components/TopInfoAction.vue";
import BottomActions from "../components/BottomActions.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import formRegister from "@/components/registers/FormRegister.vue";
import customStore from "@/services/customStore";
import moduleMixin from "../mixins/moduleMixin";

export default {
  name: "CRUDRegister",
  mixins: [CRUDMixin, moduleMixin],
  props: {
    pathBack: {type: String, default: "patients"},
  },
  components: {
    formRegister,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
    BottomActions,
  },
  data() {
    return {
      print: false,
      snackbar: false,
      text: '',
      timeout: 6000,
      embedded: 'careRequirements,currentCondition,diagnoses,laboratoryAnalysis,legalRepresentative,medicalInfo&medications',
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/patients",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nuevo"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } Registro`,
          disabled: true,
          path: "",
        },
      ],
      currentElement: {
        personalInfo: {
          firstName: '',
          lastName: '',
          genre: '',
          maritalStatus: '',
          birthday: '',
          address: ''
        },
        legalRepresentative: {
          firstName: null,
          lastName: null,
          age: 0,
          relationship: '',
          address: '',
          phone: '',
          occupation: ''
        },
        careRequirements: {
          mobilityOptions: '',
          dressOptions: '',
          hygieneOptions: '',
          bladderControlOptions: '',
          feedingOptions: ''
        },
        currentCondition: {
          visualLimitation: '',
          emotionalState: '',
          auditoryLimitation: '',
          behavioralDisorders: '',
          mealPreparation: '',
          householdTasks: '',
          orientation: '',
          memoryDisorders: '',
          inconsistenciesCommunication: ''
        },
        medicalInfo: {
          oxygenUsageHoursPerDay: '',
          pressureUlcerDescription: '',
          doctorLastName: '',
          doctorFirstName: '',
          doctorMiddleName: '',
          certificateIn: '',
          professionalLicenseNumber: '',
          medicalCheckupFrequency: '',
          lastImmunization: '',
          covid19Vaccine: '',
          infectiousDisease: '',
          weight: 0,
          height: 0
        },
        laboratoryAnalysis: [],
        diagnoses: [],
        medications: [],
      },
      module: "patients",
      titleCreate: "Crear nuevo registro",
      titleEdit: "Editar registro",
    };
  },
  methods: {
    async fillData() {
      try {
        await this.getPrincipalInformation()
        await this.getCareRequirements()
        await this.getCurrentCondition()
        await this.getMedicalInfo()
        await this.getLaboratoryAnalysis()
        await this.getDiagnoses()
        await this.getMedications()
      } catch {
        this.type = "error";
        this.dialog = true;
      }
    },
    async getPrincipalInformation() {
      const res = await customStore.getDataCrudByModule(
          'patients',
          this.$route.params.id
      );
      this.currentElement.personalInfo = res
      if (res?.legalRepresentativeId) {
        await this.getLegalRepresentative({legalRepresentativeId: res.legalRepresentativeId})
      }
    },
    async getLegalRepresentative({legalRepresentativeId = null}) {
      const res = await customStore.getDataCrudByModule(
          'legal-representatives',
          legalRepresentativeId,
      );
      this.currentElement.legalRepresentative = res
    },
    async getCareRequirements() {
      const newParams = {
        patientId: this.$route.params.id,
      }
      const res = await customStore.getDataByModuleAndParam('care-requirements', '', 0, 100000, "", "", "", newParams)

      if (res?.data?.length) {
        [this.currentElement.careRequirements] = res.data
      }
    },
    async getCurrentCondition() {
      const newParams = {
        patientId: this.$route.params.id,
      }
      const res = await customStore.getDataByModuleAndParam('current-condition', '', 0, 100000, "", "", "", newParams)

      if (res?.data?.length) {
        [this.currentElement.currentCondition] = res.data
      }
    },
    async getMedicalInfo() {
      const newParams = {
        patientId: this.$route.params.id,
      }
      const res = await customStore.getDataByModuleAndParam('medical-infos', '', 0, 100000, "", "", "", newParams)

      if (res?.data?.length) {
        [this.currentElement.medicalInfo] = res.data
      }
    },
    async getLaboratoryAnalysis() {
      const newParams = {
        patientId: this.$route.params.id,
      }
      const res = await customStore.getDataByModuleAndParam('laboratory-analysis', '', 0, 100000, "", "", "", newParams)

      if (res?.data?.length) {
        this.currentElement.laboratoryAnalysis = res.data
      } else {
        this.currentElement.laboratoryAnalysis = []
      }
    },
    async getDiagnoses() {
      const newParams = {
        patientId: this.$route.params.id,
      }
      const res = await customStore.getDataByModuleAndParam('diagnoses', '', 0, 100000, "", "", "", newParams)

      if (res?.data?.length) {
        this.currentElement.diagnoses = res.data
      } else {
        this.currentElement.diagnoses = []
      }
    },
    async getMedications() {
      const newParams = {
        patientId: this.$route.params.id,
      }
      const res = await customStore.getDataByModuleAndParam('medications', '', 0, 100000, "", "", "", newParams)

      if (res?.data?.length) {
        this.currentElement.medications = res.data
      } else {
        this.currentElement.medications = []
      }
    },
    async loadData() {
    },
    async beforeFillData() {
    },
    async afterSend() {
    },
    beforeSend() {
      this.cleanObject(this.currentElement)
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === '' || obj[propName] === null || obj[propName] === 0) {
          delete obj[propName];
        } else if (Array.isArray(obj[propName])) {
          obj[propName] = obj[propName].filter(item => Object.values(item).some(value => value !== '' && value !== null && value !== 0));
          if (obj[propName].length === 0) delete obj[propName];
        } else if (typeof obj[propName] === 'object') {
          this.cleanObject(obj[propName]);
          if (Object.keys(obj[propName]).length === 0) delete obj[propName];
        }
      }
    },
    async addNewAnalysis(action, item) {
      if (action === 'create') {
        if (this.$route.params.id) {
          const data = {description: '', date: '', patientId: this.$route.params.id}
          await customStore.postDataByModule('laboratory-analysis', data)
          this.snackbar = true
          this.text = 'se ha creado correctamente'
        } else {
          this.currentElement.laboratoryAnalysis.push({description: '', date: ''})
        }
      } else {
        await customStore.postDataByModule('laboratory-analysis', item)
        this.snackbar = true
        this.text = 'se ha guardado correctamente'
      }
      if (this.$route.params.id) {
        await this.getLaboratoryAnalysis()
      }
    },
    async deleteAnalysis(item, index) {
      if (item?.uuid) {
        try {
          await customStore.deleteByModule('laboratory-analysis', item)
          await this.getLaboratoryAnalysis()
          this.snackbar = true
          this.text = 'se ha borrado correctamente'
        } catch {
          this.type = "error";
          this.dialog = true;
        }
        return
      }
      this.currentElement.laboratoryAnalysis.splice(index, 1)
    },
    async addNewDiagnosis(action, item) {
      if (action === 'create') {
        if (this.$route.params.id) {
          const data = {description: '', patientId: this.$route.params.id}
          await customStore.postDataByModule('diagnoses', data)
          this.snackbar = true
          this.text = 'se ha creado correctamente'
        } else {
          this.currentElement.diagnoses.push({description: ''})
        }
      } else {
        await customStore.postDataByModule('diagnoses', item)
        this.snackbar = true
        this.text = 'se ha guardado correctamente'
      }
      if (this.$route.params.id) {
        await this.getDiagnoses()
      }
    },
    async deleteDiagnosis(item, index) {
      if (item?.uuid) {
        try {
          await customStore.deleteByModule('diagnoses', item)
          await this.getDiagnoses()
          this.snackbar = true
          this.text = 'se ha borrado correctamente'
        } catch {
          this.type = "error";
          this.dialog = true;
        }
        return
      }
      this.currentElement.diagnoses.splice(index, 1)
    },

    async saveAllItemsBeforeGet () {
      try {
        let medicationPromise = []
        console.log(this.currentElement.medications)
       for(let i = 0; i <  this.currentElement.medications.length; i++) {
         medicationPromise.push(customStore.postDataByModule('medications', this.currentElement.medications[i]))
       }
       await Promise.all(medicationPromise)
      }catch {
        this.type = "error";
        this.dialog = true;
      }
    },

    async addNewMedication(action, item) {
      if (action === 'create') {
        if (this.$route.params.id) {
          const data = {description: '', schedule: '', patientId: this.$route.params.id}
          await customStore.postDataByModule('medications', data)
          this.snackbar = true
          this.text = 'se ha creado correctamente'
        } else {
          this.currentElement.medications.push({description: '', schedule: ''})
        }
      } else {
        await customStore.postDataByModule('medications', item)
        this.snackbar = true
        this.text = 'se ha guardado correctamente'
      }
      if (this.$route.params.id) {
        await this.saveAllItemsBeforeGet()
        await this.getMedications()
      }
    },
    async deleteMedication(item, index) {
      if (item?.uuid) {
        try {
          await customStore.deleteByModule('medications', item)
          await this.getMedications()
          this.snackbar = true
          this.text = 'se ha borrado correctamente'
        } catch {
          this.type = "error";
          this.dialog = true;
        }
        return
      }
      this.currentElement.medications.splice(index, 1)
    },
    async save() {
      try {
        if (this.$route.params?.crudAction && this.$route.params?.crudAction === "create") {
          const {personalInfo} = {
            ...this.currentElement
          }
          const dataCopy = {
            ...this.currentElement
          }

          delete dataCopy.personalInfo

          const data = {
            ...personalInfo,
            ...dataCopy
          }
          await customStore.postDataByModule('patients', data)
        } else {
          await this.saveAllItemsBeforeGet()
          await this.postEditRegister()
        }
        this.type = "success";
        this.dialog = true;
      } catch {
        this.type = "error";
        this.dialog = true;
      }
    },
    async postEditRegister() {
      this.validateInfoToPost()
      const res = await customStore.postDataByModule('legal-representatives', this.currentElement.legalRepresentative)
      this.currentElement.personalInfo.legalRepresentativeId = res?.uuid
      await customStore.postDataByModule('patients', this.currentElement.personalInfo)
      await customStore.postDataByModule('care-requirements', this.currentElement.careRequirements)
      await customStore.postDataByModule('current-condition', this.currentElement.currentCondition)
      await customStore.postDataByModule('medical-infos', this.currentElement.medicalInfo)
    },
    validateInfoToPost() {
      if (!this.currentElement?.legalRepresentative?.patientId) {
        this.currentElement.legalRepresentative.patientId = this.$route.params.id
      }
      if (!this.currentElement?.careRequirements?.patientId) {
        this.currentElement.careRequirements.patientId = this.$route.params.id
      }
      if (!this.currentElement?.currentCondition?.patientId) {
        this.currentElement.currentCondition.patientId = this.$route.params.id
      }
      if (!this.currentElement?.medicalInfo?.patientId) {
        this.currentElement.medicalInfo.patientId = this.$route.params.id
      }
    },
    async printContract() {
      try {
        const completeName = `${this.currentElement?.personalInfo?.firstName}_${this.currentElement?.personalInfo?.lastName}` || 'archivo'
        await customStore.getPdfRegister(this.$route.params.id, completeName)
        this.print = true
        this.dialog = true
        this.type = 'success'
      } catch {
        this.print = false
        this.dialog = true
        this.type = 'error'
      }
    },
    async actionIcon() {
      await this.postEditRegister()
      await this.printContract()
    },
    async closeAction() {
      this.print = false
      this.dialog = false
      if (this.$route.params.id) {
        await this.fillData()
      }
    }
  },
};
</script>

<style scoped>

</style>
